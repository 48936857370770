/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import './Layout.css';

const Layout = ({ children }) => {
  return (
    <div className="container">
      <Helmet>
        <link href='//fonts.googleapis.com/css?family=Lato:300,400' rel='stylesheet' type='text/css' />
        <script src="https://use.fontawesome.com/d58c36bd83.js" />
      </Helmet>
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
